<template>
  <div>
    <div class="d-flex w-100">
      <div style="width: 450px">
        <div>
          <h5 class="app-label section-label">
            <span class="align-middle">Hospital</span>
          </h5>
          <v-select id="hospital" v-model="selectedClient" :clearable="false" label="text" :options="clients"
            :reduce="option => option.value" class="bg-white w-100 z-index-n1" @input="onChangeClient" />
        </div>
        <month-picker-input @change="onChangeMonth" :default-month="defaultMonth" class="mt-1" />
      </div>
      <div class="flex-grow-1"></div>
      <div class="d-flex flex-column justify-content-end pr-2">
        <b-form-checkbox id="checkbox-1" v-model="listType" style="margin-bottom: 8px">
          By logs
        </b-form-checkbox>
      </div>
      <div class="d-flex flex-column justify-content-end">
        <b-button variant="outline-primary" class="btn-wishlist" style="padding-top: 12px; height: 40px;"
          @click="downloadCsv">
          <feather-icon icon="DownloadIcon" class="mr-50" />
          <span>Export</span>
        </b-button>
      </div>
    </div>
    <div class="mt-2">
      <b-table striped hover :items="items" v-if="!listType"></b-table>
      <b-table striped hover :items="logItems" v-if="listType"></b-table>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <div class="triangle"></div>
      <div class="time-wrapper">
        Total: {{ `${Math.floor(hours / 60)} hours ${hours % 60} minute(s)` }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BTable, BFormCheckbox
} from 'bootstrap-vue'
import moment from 'moment'
import { MonthPickerInput } from 'vue-month-picker'
import Parse from "parse/dist/parse.min.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import vSelect from 'vue-select'
import { convertMinToHr } from "@core/utils/utils";

export default {
  components: {
    BButton,
    BTable,
    BFormCheckbox,
    MonthPickerInput,
    vSelect
  },
  data() {
    return {
      defaultMonth: new Date().getMonth() + 1,
      hours: 0,
      items: [],
      logItems: [],
      clients: [],
      selectedClient: "",
      date: null,
      listType: false
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  created() {
    this.fetchClients()
  },
  methods: {
    onChangeClient() {
      this.loadData(this.date)
    },
    onChangeMonth(date) {
      this.date = date
      this.loadData(date)
    },
    convertToMins(hours) {
      if (!hours) {
        return 0
      }

      return parseInt(hours.split(":")[0]) * 60 + parseInt(hours.split(":")[1])
    },
    loadData(date) {
      const Booking = Parse.Object.extend("Booking");
      const query = new Parse.Query(Booking);

      query.include("client")
      query.include("assistant")

      query.greaterThanOrEqualTo("start", date.from);
      query.lessThanOrEqualTo("start", date.to);
      // query.exists("time");

      if (this.selectedClient != "-1" && this.selectedClient != "") {
        const Client = Parse.Object.extend("Client");
        const client = new Client();
        client.id = this.selectedClient;
        query.equalTo("client", client);
      }

      this.hours = 0
      query
        .find()
        .then((results) => {
          const ids = results.map(result => result.id)
          const Log = Parse.Object.extend("Log");
          const logsQuery = new Parse.Query(Log);

          logsQuery.containedIn("booking", ids)
          logsQuery.find().then((logs) => {
            this.hours = 0;
            this.items = results.filter(result => result.attributes.assistant).map((result => {
              const duration = moment.duration(moment(result.attributes.end).diff(moment(result.attributes.start))).asMinutes();
              this.hours += duration;
              return {
                date: moment(result.attributes.start).format("D MMM YYYY"),
                when: `${moment(result.attributes.start).format("H:mm")} - ${moment(result.attributes.end).format("H:mm")}`,
                hospital: result.attributes.client.attributes.name,
                department: result.attributes.client.attributes.department,
                assistant: `${result.attributes.assistant.attributes.firstName} ${result.attributes.assistant.attributes.lastName}`,
                tasks_completed: logs.filter(log => log.attributes.booking.id === result.id).length,
                hours_logged: `${Math.floor(duration / 60)} hours ${duration % 60} minute(s)`,
              }
            }))

            this.logItems = logs.map(log => {
              const booking = results.find(booking => booking.id === log.attributes.booking.id)
              return {
                date: `${moment(log.attributes.booking.attributes.start).format("D MMM YYYY")} ${moment(log.attributes.booking.attributes.start).format("H:mm")} - ${moment(log.attributes.booking.attributes.end).format("H:mm")}`,
                hospital: booking.attributes.client.attributes.name,
                department: booking.attributes.client.attributes.department,
                assistant: `${booking.attributes.assistant.attributes.firstName} ${booking.attributes.assistant.attributes.lastName}`,
                doctor: log.attributes.doctor,
                description: log.attributes.comments,
                // hoursLogged: `${Math.floor(log.attributes.time / 60)} hours ${log.attributes.time % 60} minute(s)`,
              }
            })
          })
        })
        .catch((ex) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching bookings",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    fetchClients() {
      const Client = Parse.Object.extend("Client");
      const query = new Parse.Query(Client);
      query
        .find()
        .then((results) => {
          this.clients = results.map(client => ({
            value: client.id,
            text: `${client.attributes.name} ${client.attributes.department}`
          }));
          this.clients.unshift({
            value: "-1", text: "All"
          })
          this.selectedClient = "-1"
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching client list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    downloadCsv() {
      if (this.listType) {
        let csv = 'Date,Hospital,Department,Assistant,Doctor,Description\n';
        this.logItems.forEach((item) => {
          csv += `${item.date},${item.hospital},${item.department},${item.assistant},${item.doctor},${item.description}`
          csv += "\n";
        });

        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'timesheet.csv';
        anchor.click();
      }
      else {
        let csv = 'Date,When,Hospital,Department,Assistant,Tasks completed,Hours Logged\n';
        this.items.forEach((item) => {
          csv += `${item.date},${item.when},${item.hospital},${item.department},${item.assistant},${item.tasks_completed},${item.hours_logged}`
          csv += "\n";
        });

        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'timesheet.csv';
        anchor.click();
      }
    }
  }
};
</script>

<style lang="scss">
.month-picker__year {
  button {
    padding-bottom: 41px;
  }
}

.month-picker-input {}

.time-wrapper {
  height: 38px;
  width: 300px;
  background: rgba(115, 103, 240, 0.12);
  padding: 10px;
  font-weight: 700;
  text-align: center;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid rgba(115, 103, 240, 0.12);

  border-top: 20px solid rgba(115, 103, 240, 0.12);
  border-bottom: 20px solid transparent
}
</style>
